.ant-modal.confirmation-modal {
  .ant-modal-content {
    border-radius: 12px;
    padding: 0;

    .ant-modal-header {
      padding: 16px 24px 0 24px;
      border: none;
      background: transparent;
    }

    .ant-modal-footer {
      display: flex;
      border: none;
      justify-content: end;
    }

    .ant-modal-body {
      padding: 0;
      .footer {
        padding: 16px 24px;
      }
      .logout-message {
        border-bottom: 1px #dddddd solid;
        padding: 24px 24px;
      }
    }

    .ant-modal-close {
      .ant-modal-close-x {
        display: flex;
        align-items: center;
        margin-top: 8px;
      }
    }
  }
}
