.side-menu {
  &__active {
    background: linear-gradient(90deg, #ff9966 0%, #8e54e9 100%);

    &:hover {
      color: white;
    }
  }
}

a.goodies {
  p {
    margin: 0 4px;
  }
}
